.l-f {
  background-color:$white;
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 41px 0;
    display: flex;
    flex-direction: column;
    @include mq-max(content) {
      padding: 41px 20px;
    }
    @include mq-max(xs) {
      flex-wrap: wrap;
    }
    //ロゴ
    &__box{
      // display: flex;
      // align-items: center;
      // justify-content: space-around;
      text-align: center;
    }
  }
  &__privacyLink {
    font-weight: 600;
    font-size: 14px;
    color: $black !important;
    transition: color $transition;
    &:hover {
      color: $keyColor !important;
    }
  }

  &__info {
    display: block;
  }
  &__logo {
    > img {
      width: 440px;
      @include mq-max(sm) {
        width: 270px;
      }
    }
  }
  &__nav {
    display: flex;
  }
  &__links {
      margin-left: 50px;
      @include mq-max(xs) {
        margin-left: 0;
        margin-bottom: 30px;
      }
      &:first-child {
        margin-left: 0;
      }
      @include mq-max(xs) {
        width: 50%;
      }
      & > li {
        a {
          display: block;
          padding: 2px 0;
        }
      }
    }

  //Copyright
  &__copyright {
    text-align: center;
    display: block;
    color: $white;
    background-color: $keyColor;
    padding: 13px 0;
    line-height: 1;
    @include mq-max(xs) {
      font-size: 10px;
    }
  }
}

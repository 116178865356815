.c-cvArea {
  position: relative;
  width: 100%;
  background: url('/inc/image/common/bg/bg_cvArea.jpg')no-repeat center / cover;
  line-height: 1;
  padding: 85px $contentPadding/2 95px;
  @include mq-max(sm) {
    padding: 71px 0 73px;
  }
  //フィルター設定
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .7;
  }
  &__inner {
    position: relative;
    z-index: 2;
    color: $white;
    max-width: 420px;
    margin: 0 auto;
    text-align: center;
    font-weight: 600;
  }
  &__memo {
    margin-bottom: 12px;
    @include mq-max(sm) {
      font-size: 12px;
      // margin-bottom: 14px;
    }
  }
  &__title {
    font-size: 26px;
    margin-bottom: 22px;
    @include mq-max(sm) {
      font-size: 20px;
      margin-bottom: 12px;
    }
  }
  &__tel {
    font-size: 28px;
    @include mq-max(sm) {
      font-size: 25px;
    }
  }

}
//サイトレイアウト構造を記述

#container {}
#main {}
#contents {
  padding: 0;
  @include mq-max(content) {
    padding-top: 60px;
  }
  @include mq-max(sm) {
    width: $full-size;
  }
}

//gridlex調整
[class*="grid"] {
  margin-bottom: -1 * $gridlexPadding; //gridlexを使うことでできてしまう下余白を相殺。
}
[class*="col-"] {
  img {
    width: 100%;
    display: block;
    line-height: 1;
  }
}
[class*="col-1"],
[class*="col-2"],
[class*="col-3"],
[class*="col-4"] {
  p {
    line-height: 1.7;
    @include mq-max(xs) {
      line-height: 1.8;
    }
  }
}

//【注意】$contentPadding以上の値を設定する場合は画面幅を縮めたときに右側に余白ができるため、早い段階でデフォルト状態に戻すか「overflow:hidden」を使用する事。
.l-Grid {
  &--20 {
    @include gridTune(20px, none);
  }
  &--40-mdDefault {
    @include gridTune(40px, md);
  }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////

//l-sectionと同じ左右余白感を付与
@mixin addSectionSidePadding {
  @include mq-max(content) {
    padding-left: $contentPadding;
    padding-right: $contentPadding;
  }
  @include mq-max(sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include mq-max(xs) {
    padding-left: $contentPadding/2;
    padding-right: $contentPadding/2;
  }
}

.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: $sectionPadding 0;
  @include mq-max(content) {
    padding: 0 $contentPadding $sectionPadding;
  }
  @include mq-max(sm) {
    padding: 0 20px $sectionPadding;//お好みで変更
  }
  @include mq-max(xs) {
    padding: 0 $contentPadding/2 $sectionPadding;//お好みで変更
  }

  //基本的に.l-section内で使用
  &__inner {
    padding: 0 0 $sectionInnerPadding;//お好みで変更
    &:last-child {
      padding-bottom: 0;
    }

    &--pbLarge {
      padding-bottom: $sectionInnerPadding + 20px;//調整して
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  //基本的に.l-section__inner内で使用
  &__content {
    padding: 0 0 30px;//調整して
    &:last-child {
      padding-bottom: 0;
    }

    &--pbLarge {
      padding-bottom: 60px;//調整して
    }
  }

  //////////////////////////////////////////////////////////////////////////
  //Modifier
  //////////////////////////////////////////////////////////////////////////
  //横幅調整用
  &--full {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    & > .l-section__inner {
      max-width: $contentWidth;
      width: 100%;
      margin: 0 auto;
      @include addSectionSidePadding;
    }
  }

  //余白バリエーション
  &--lg {
    padding-top: $sectionPadding + 30px;
    padding-bottom: $sectionPadding + 30px;
    @include mq-max(content) {
      padding-top: 0;
    }
  }
  &--noTop {
    padding-top: 0;
  }
  &--pbLarge {
    padding-bottom: 90px;//調整して
  }
  &--pb2x {
    padding-bottom: $sectionPadding * 2;
    @include mq-max(sm) {
      padding-bottom: 90px;//調整して
    }
  }
}


//max-width(できるだけl-sectionやl-section__innerと同じ場所では使わない。内部で使う)
.l-mw {
  margin: 0 auto;
  width: 100%;
  &--contentWidth {
    max-width: $contentWidth;
  }
  &--800 {
    max-width: 800px;
  }
}


//background共通設定
@mixin bg_scss() {
  @include mq-max(content) {
    padding-top: $sectionPadding;
    margin: 0 0 $sectionPadding;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

//背景色（.l-sectionを囲って使う）
.l-bg {
  @include bg_scss();
  &--keyColor {
    background-color: $keyColor;
  }
  &--gray {
    background-color: $bgColor;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ページ外アンカー位置調整（別ページのidに飛ぶとき。JSだけの調整で上手くいかない時）

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-anchorFix {
  padding-top: $headerHeight;
  margin-top: -1 * $headerHeight;
  @include mq-max(content) {
    padding-top: $headerHeight-sp;
    margin-top: -1 * $headerHeight-sp;
  }
  //微調整
  &--10px {
    padding-top: $headerHeight + 10px;
    margin-top: -1 * $headerHeight - 10px;
    @include mq-max(content) {
      padding-top: $headerHeight-sp + 10px;
      margin-top: -1 * $headerHeight-sp - 10px;
    }
  }

  //l-section__inner用
  // &__inner {}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

//互い違い
.l-alternate {
  &__wrap {
    margin-bottom: 90px;
    @include mq-max(xs) {
      margin-bottom: 60px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__img {
    @include mq-max(xs) {
      margin-bottom: -15px;
    }
  }
  &__desc {}
}

.l-navigation {
  //  background-color: $white;
    height: 100%;
    @include mq-max($changePoint) {
      display: none;
      height: auto;
      html.open &.active > ul {
        overflow-y: scroll;
        height: calc(100vh - #{$headerHeight-sp});//画面高さ - fixedヘッダーの高さ
        padding-bottom: 120px;
      }
    }
    &.active {
      transform: translateY(0%);
    }
    &__inner {
      padding: 0;
      display: flex;
      height: 100%;//menu.jsで指定した値が優先される
      @include mq-max($changePoint) {
        display: block;
        height: calc(100vh - #{$headerHeight-sp});
        overflow-y: scroll;
      }
    }
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      &:last-child {
        margin-right: 0;
      }
      @include mq-max($changePoint) {
        display: block;
        height: auto;
        margin-right: 0;
        border-top: 1px solid $gray;
        &:last-child{
          border-bottom: 1px solid $gray;
        }
      }

      a {
        //フォント指定
        font-family: $sanserif;
        font-weight: 500;
        @include mq-max($changePoint) {
          background-color: $white;
        }
      }
      &>a {
        text-decoration: none;
        color: $textColor;
        display: inline-block;
        padding: 10px 20px;
        @include mq-max(lg) {
          padding: 10px 12px;
        }
        @include mq-max($changePoint) {
          text-align: center;
          display: block;
          padding: 25px 10px;
          line-height: 1;
        }
        &:hover {
          color: $keyColor;
        }
      }

      //お問い合わせボタン
      &--contact {
        margin-left: 23px;
        @include mq-max($changePoint) {
          margin-left: 0;
          height: 100%;
        }
        &:before {
          content: none;
        }
        & > a {
          position: relative;
          font-family: $sanserif;
          color: $white;
          line-height: 1;
          font-size: 14px;
          padding: 18px 33px;
          z-index: 0;
          background: $keyColor;
          border: 1px solid $keyColor;
          transition: all $transition !important;
          @include mq-max($changePoint) {
            margin-left: 0;
            padding: 29.5px 10px;
          }
          &:hover {
            background: $white;
          }
        }
      }


      //下層メニュー
      &.has-dropdown {
        &>a{
          position: relative;
          &:after {
            @include mq-max($changePoint) {
              @include fa('f067');
              position: absolute;
              top: 50%;
              right: 16px;
              transform: translateY(-50%);
            }
          }
          &.has-dropdown {
            &:after {
              @include mq-max($changePoint) {
                content: '\f068';
              }
            }
          }
        }

        // クリック時
        &.is-opened {
          .l-dropDown {
            top: $headerHeight; // headerの高さ
            opacity: 1;
            visibility: visible;
            @include mq-max($changePoint) {
              top: auto;
              opacity: 1;
              display: block;
            }
            &>li {
              border-bottom: 1px solid $gray;
              &:first-child {
                border-top: 1px solid $gray;
              }
              &:last-child {
                border-bottom: none;
              }
            }
            a {
              transition: color $transition,
                          height $transition;
              &:hover {
                color: $keyColor;
              }
              @include mq-max($changePoint) {
                opacity: 1;
                line-height: 1.5;
                height: 54px;
                padding: 15px 10px 15px 25px;
                overflow: hidden;
              }
            }
          }
        }

        .l-dropDown {
          position: absolute;
          top: $headerHeight - 30px; // headerの高さ - 30px
          visibility: hidden;
          opacity: 0;
          transition: all .5s ease;
          z-index: -1;
          overflow: hidden;
          @include mq-max($changePoint) {
            position: relative;
            transition: none;
            top: auto;
            opacity: 1;
            visibility: visible;
            z-index: 1;
          }
          li {
            border-bottom: 1px solid $gray;
            @include mq-max($changePoint) {
              border-bottom: none;
            }
            &:last-child {
              border: none;
            }
            a {
              background-color: $white;
              padding: 10px 15px;
              white-space: nowrap;
              display: block;
              text-decoration: none;
              color: $textColor;
              width: 100%;
              @include mq-max($changePoint) {
                line-height: 0;
                height: 0;
                padding: 0;
                opacity: 0;
                transition: height $transition,
                            opacity 0.4s,
                            padding $transition,
                            line-height $transition;
              }
            }
          }
        }
      }
    }
  }
  //ハンバーガークリック時背景暗く
  .c-blackFilm {
    pointer-events: none;
    transition: all 0.5s ease;
    opacity: 0;
    background: $black;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    &--visible {
      @include mq-max($changePoint) {
        opacity: .5;
        pointer-events: auto;
      }
    }
  }

// カード
.c-card {
  &__body {
    padding: 30px 25px;
    @include mq-max(xs) {
      padding: 15px 0 10px;
    }
  }

  &__title {
    font-family: $sanserif;
    font-size: 18px;
  }
  &__text {
    margin-bottom: 35px;
    @include mq-max(sm) {
      margin-bottom: 15px;
    }
  }
}
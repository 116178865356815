///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-ttl {
  &-2 {
    position: relative;
    font-size: 32px;
    margin-bottom: 60px;
    font-weight: 600;
    @include mq-max(lg) {
      padding-left: 20px;
    }
    @include mq-max(content) {
      padding-left: 20px;
    }
    @include mq-max(sm) {
      font-size: 20px;
      margin-bottom: 30px;
    }
    & > small {
      display: block;
      font-family: $roboto;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 10px;
      @include mq-max(sm) {
        font-size: 12px;
      }
    }
    &:before {
      position: absolute;
      left: -20px;
      content: "";
      width: 2px;
      height: 90%;
      background: $keyColor;
      @include mq-max(lg) {
        left: 0;
      }
    }
    &--mbNone {
      margin-bottom: 0;
    }
  }

  &-3 {
    font-size: 23px;
    @include mq-max(sm) {
      font-size: 18px;
    }
  }

  &-4 {
    font-size: 16px;
    &--mbNone {
      margin-bottom: 0;
    }
  }
}
